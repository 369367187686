import styled from "styled-components";
import DownArrow from "assets/images/down-arrow.svg";
import Tick from "assets/images/tick.png";
import NoData from "assets/images/no-data-placeholder.svg";
import QuickLinksBg from "assets/images/quick-links-bg.png";

export const WhitePaperWrap = styled.div``;

export const MCard = styled.div`
  // box-shadow: 0px 2px 5px #0000001a;
  border: 1px solid #E7EBF0;
  border-radius: 10px;
  padding: 1.875rem 1.56rem;
  background-color: white;
`;

export const WhitePaperTitle = styled.div`
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin: 2.5rem 0 1.5rem;
`;

export const WhitePaperInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const WhitePaperInnerCard = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 175px 1fr;
  gap: 30px;
`;

export const WhitePaperCardImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const WhitePaperCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.5rem;
  h3 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #142c1f;
    margin: 0;
  }
  p {
    color: #44554c;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    margin: 1rem 0;
  }

  button {
    background: #f5f5f5;
    border-radius: 0.3125rem;
    padding: 0.5rem 0.25rem;
    color: #44554c;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    gap: 0.5rem;
    width: fit-content;
    text-align: left;
  }
`;

export const ClientDetailsContentHeading = styled.div`
  font-size: 1.25rem;
  color: #142c1f;
  margin-bottom: 0.3125rem;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: 0.9375rem;
  color: #818d88;
  margin-top: 1.25rem;
`;

export const LeftWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  width: 100%;
`;

export const LogoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-left: 2.5rem;

  img {
    width: 8rem;
  }
`;

export const UpperBox = styled.div`
  width: 100%;
`;

export const LowerBox = styled.div`
  width: 100%;
`;

export const LastNavItem = styled.div`
  border-top: 2px solid rgba(275, 275, 275, 0.15);

  ul {
    margin-bottom: 0;
    li {
      cursor: pointer;
      padding: 1rem 0.5rem 1rem 2.5rem !important;
    }
  }
`;

export const SocialWrap = styled.div`
  width: 100%;
  padding: 1.5rem 2.5rem;

  h6 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }
`;

export const SocialBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  a {
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

export const MenuLinks = styled.div`
  padding: 2.5rem 0 0 0;
`;

export const DashboardContent = styled.div`
  padding: 20px;
  border-radius: 15px;
  margin: 0px 20px;
  margin-top: 10px;
  box-shadow: 0px 2px 20px #0000001a;
  background-color: white;
  height: 90vh;
  overflow-y: scroll;
  position: fixed;
  width: calc(100% - 150px);

  &::-webkit-scrollbar {
    display: none;
  }
`

export const DashboardHeadline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  span {
    font-size: 1.5rem;
  }
`

export const DashboardWrap = styled.div`  
  display: flex;
`;

export const DashboardWrapRight = styled.div`
  width: 380px;
  position: fixed;
  right: 30px;
  height: 82vh;
  overflow-y: scroll;
  padding-bottom: 70px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DashboardWrapLeft = styled.div`
  width: calc(100% - 400px);
`;

export const DashboardLefttop = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const DashboardLeftBottom = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 1.25rem;
`;

export const DashboardBottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DashboardBottomRight = styled.div``;

export const SelfCard = styled.div`
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const SelfCardHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .chart-filters {
    display: flex;
    align-items: center;
  }

  .chart-refs {
    font-size: .8rem;
    display: flex;

    .pro {
      color: #46CF96;
      &:before {
        background: #46CF96;
      }
    }

    .plus {
      color: #44554C;
      &:before {
        background: #44554C;
      }
    }

    > span {
      margin-right: 25px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        background: black;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        right: calc(100% + 5px);
        top: calc(50% - 5px);
      }
    }
  }

  h3 {
    margin: 0;
    color: #142c1f;
    font-weight: 500;
    font-size: 1rem;
  }

  select {
    background-color: #f5f5f5;
    border-radius: 0.3125rem;
    outline: none;
    border: none;
    padding: 0.625rem 2.25rem 0.625rem 0.9375rem;
    color: #44554c;
    font-size: 1rem;
    line-height: 1.1875rem;
    background-image: url("${DownArrow}");
    background-repeat: no-repeat;
    background-position: center right 0.3125rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: 400;
  }
  a {
    color: #44554c;
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const DashboardCheckList = styled.div`
  h4 {
    margin: 0;
    color: #142c1f;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
  }
  p {
    margin: 1.25rem 0 1.875rem;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
`;

export const SelfMadeCheckBoxWrap = styled.div`
  font-size: .9rem;
`;
export const CheckContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .completed, .start {
    margin-left: 10px;
  }

  .completed {
    color: #2DCF96;
  }

  .start {
    cursor: pointer;
  }
`
export const SelfMadeCheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  span {
    cursor: pointer;
  }
  .form-control {
    color: #142c1f;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 400;
    display: grid;
    grid-template-columns: 28px 1fr;
    align-items: center;
    gap: 0.625rem;
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  .form-control--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.4rem;
    height: 1.4rem;
    border: 0.125rem solid #a5b5af;
    border-radius: 50%;
    transform: translateY(-0.075em);
    background-color: #fff;

    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.9rem;
    height: 0.9rem;
    background-position: center;
    background-size: contain;
    background-image: url("${Tick}");
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  input[type="checkbox"]:checked {
    background-color: #2ecf96;
    border-color: #2ecf96;
  }
  input[type="checkbox"]:checked + span {
    text-decoration: line-through;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  span {
    font-weight: 400;
  }

  &.line-through {
    text-decoration: line-through;
  }
`;
export const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  svg {
    fill: #a5b5af;
  }
`;

export const ProfileBox = styled.div`
  border-radius: 0.625rem;
  border: 1px solid #E7EBF0;
  padding: 0.9375rem 1.25rem;
  display: flex;
  align-items: center;

  .MuiAvatar-colorDefault {
    width: 3.75rem;
    height: 3.75rem;
  }

  img {
    min-width: 3.75rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
  }
`;

export const InnerMenuInfo = styled.div`
  margin-left: 15px;
  h5 {
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.25rem;
    color: #142c1f;
    margin: 0;
  }
  p {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #a5b5af;
    margin-bottom: 0;
    margin-top: 0.5rem;
    font-weight: 400;
  }
`;

export const UrlBox = styled.div`
  margin-bottom: 30px;
  h5 {
    margin: 0 0 1.875rem;
    color: #142c1f;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const UrlBoxWrap = styled.div`
  /* height: 135px;
  overflow: auto; */
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
export const URlBoxInner = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #818d88;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  a {
    color: #44554C;
    font-size: 1rem;
    line-height: 1.4375rem;
    font-weight: 400;
  }

  .url-wrapper {
    display: flex;
    justify-content: space-between;
  }
`;

export const NewsWrap = styled.div`
  padding-top: 1.875rem;
  margin-bottom: 30px;

  h5 {
    margin: 0 0 1.875rem;
    color: #142c1f;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;
export const NewsWrapInner = styled.div`
  height: auto;
  overflow: auto;
`;
export const NewsBox = styled.div`
  margin-bottom: 1.875rem;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: #142c1f;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
  }
  p {
    margin: 0.625rem 0 0.9375rem;
    color: #44554c;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
  span {
    color: #a5b5af;
    font-weight: 400;
    font-size: 0.875rem;
  }
`;

// Chart

export const ChartWrap = styled.div`
  // box-shadow: 0px 2px 5px #0000001a;
  border: 1px solid #E7EBF0;
  border-radius: 0.625rem;
  padding: 1.25rem;
  align-items: center;
  display: grid;
  grid-template-columns: 5.75rem 1fr;
  gap: 1.25rem;
  background-color: #fff;
`;

export const ChartLeft = styled.div`
  border-radius: 0.625rem;
  height: 5.75rem;
  background: #f8f8f8;
  overflow: hidden;
`;

export const ChartRight = styled.div``;

export const ChartRightTop = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 0.375rem;
  margin: 0 0 0.625rem;
  flex-wrap: wrap;
  h3 {
    color: #142c1f;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 0;
  }
  span {
    color: #818d88;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-weight: 400;
  }
`;

export const ChartTabWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: end;
`

export const ChartTab = styled.span`
  font-size: 14px;
  cursor: pointer;
  ${({active}) => active && 
  `
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: solid 3px #2ECF96;
  `}
`

export const ChartRightBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  h4 {
    font-size: 2rem;
    line-height: 2.125rem;
    font-weight: 500;
    color: #44554c;
    margin: 0;
  }
  span {
    border-radius: 0.3125rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    color: #fff;
  }
`;

export const TableOverallContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 0;
  /* height: 500px; */
  overflow: auto;
  // box-shadow: 0px 2px 5px #0000001a;
  border: 1px solid #E7EBF0;
  box-sizing: border-box;
  background-color: white;
  // box-shadow: none;
  border: 1px solid #E7EBF0;
  th,
  td {
    padding: 1rem;
  }

  tbody {
    td {
      border-bottom: 1px solid #ececec;
      &:last-child {
        text-align: right;
      }
    }
    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const TableHeadingContainer = styled.thead`
  /* height: 54px; */
  color: #a5b5af;
  font-size: 14px;
  text-align: left;
  position: sticky;
  top: 0;
  background: white;
  z-index: 500;
`;

export const TableRowContainer = styled.tr`
  font-size: 16px;
  color: #44554c;
  border-bottom: 1px #f5f5f5;
`;

export const TablePaginationDetailsContainer = styled.div`
  background-color: white;
  margin-top: 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  // box-shadow: 0px 2px 5px #0000001a;
  border: 1px solid #E7EBF0;
  border-radius: 8px;
`;

export const TablePaginationNumbersContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TablePaginationNumber = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: ${(props) => (props.active ? "none" : "1px solid #1C9D87")};
  background: ${(props) => (props.active ? "#1C9D87" : "white")};
  color: ${(props) => (props.active ? "white" : "#1C9D87")};
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 25px;
  font-size: 16px;
`;

export const ChipLight = styled.div`
  width: fit-content;
  border-radius: 30px;
  background: #dddddd;
  color: #a3aba9;
  text-align: center;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 400;
`;

export const ChipDark = styled.div`
  width: fit-content;
  padding: 6px 12px;
  font-weight: 400;
  border-radius: 30px;
  background: #44554c;
  color: #d4d7d5;
  text-align: center;
  font-size: 13px;
`;

export const TableWrap = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    .table-button {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #E7EBF0;
      text-decoration: none;
      color: #141414;
    }

    .lead-status {
      background: #2ECF96;
      color: white;
      padding: 7px 10px;
      border-radius: 17px;
      font-size: .7rem;
      text-transform: uppercase;
    }

    tr {
      td {
        padding: 1rem 0;
        color: #44554c;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;

        > div {
          text-align: right;
        }
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px solid #ececec;
        }
      }
    }

    thead {
      text-align: left;
      background: #F6F8FA;
      color: #535865;
      
      th {
        padding: 15px 0px;
      }

      th:nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 10px;
      }
      
      th:last-child {
        text-align: right;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-right: 10px;
      }
    }
  }
`;

export const ExpertCall = styled.div`
  // box-shadow: 0px 2px 5px #0000001a;
  border: 1px solid #E7EBF0;
  border-radius: 0.625rem;
  padding: 1.25rem;
  background-color: white;
  margin-bottom: 1.25rem;
`;

export const ExpertCallHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  h3 {
    margin: 0;
    color: #142c1f;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  a {
    color: #44554c;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
  }
`;

export const ExpertContent = styled.div`
  border: 1px solid #E7EBF0;  
  border-radius: 0.625rem;
  padding: 1.25rem;

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    width: max-content;
    border-radius: 0.3125rem;
    padding: 0.3125rem 0.625rem;
    font-size: 0.8125rem;
    line-height: 0.9375rem;
    font-weight: 500;

    &.danger {
      color: #ee2b2b;
      background: #ee2b2b1a;
    }

    &.success {
      color: #2ecf96;
      background: #2ecf961a;
    }

    &.warning {
      color: #ffba31;
      background: #ffba311a;
    }
  }

  .referant {
    color: #142c1f;
    font-weight: 500;
  }

  .date-time {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: 1.5rem;

    span {
      color: #a5b5af;
      font-size: 1rem;
      line-height: 1.1875rem;
      font-weight: 300;
    }
  }
`;

export const CardBox = styled.div`
  border: 1px solid #E7EBF0;
  border-radius: 0.625rem;
  padding: 1.25rem;
  margin-bottom: 10px;
`

export const PromoSection = styled.div`
  h5 {
    margin: 0 0 1.875rem;
    color: #142c1f;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  p {
    margin: 0.625rem 0 0.9375rem;
    color: #44554c;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
`

export const PromoList = styled.div`
  color: #44554C;
  line-height: 1.25rem;

  div {
    display: flex;
    align-items: center;
    padding: 7px 0px;
  }

  div > * {
    margin-right: 10px;
  }

  div span:nth-child(1) {
    background: #2ECF96;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const PromoCopyLink = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .url-placeholder {
    background: #F6F8FA;
    color: #2C2C2C;
    padding: 10px 20px;
    font-size: .9rem;
    border-radius: 7px;
  }

`

export const QuickLinks = styled.div`
  padding: 1.25rem;
  margin-bottom: 10px;
  background: url('${QuickLinksBg}') no-repeat;
  background-size: cover;
  background-position: center;

  h3 {
    margin: 0;
    color: #504F54;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .sidebar-quick-links {
    margin-top: 50px;
    display: flex;
    align-items: center;

    > * {
      margin-right: 20px;
    }
  }
`

export const StatsCard = styled.div`
  border: 1px solid #E7EBF0;
  border-radius: 0.625rem;
  font-size: .9rem;

  .stats-card-header {
    border-bottom: 1px solid #E7EBF0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:nth-child(2) {
      background: #F3F4F6;
      padding: 7px 14px;
      border-radius: 7px;
    }
  }

  .stats-card-content {
    border-bottom: 1px solid #E7EBF0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invisible {
      opacity: 0;
    }

    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span:nth-child(1) {
        padding: 5px 10px;
        border-radius: 15px;
        margin-bottom: 15px;
      }

      span:nth-child(2) {
        // font-size: .75rem;
      }
    }

    div:nth-child(1) {
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        color: #686E7C;
        margin-bottom: 15px;
      }

      span:nth-child(2) {
        font-weight: 500;
        font-size: 2rem;
      }
    }

    .warning-percentage {
      background: #EE2A2B;
      color: white;
    }

    .green-percentage {
      color: #047A54;
      background: #E6FAF3;
    }
  }
`

export const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
  height: 95%;

  .icon {
    background: url('${NoData}') no-repeat;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
  }

  .body {
    font-weight: 200;
  }
`
