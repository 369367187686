import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  calculateChartPercentage,
  removeDuplicates,
  formatNewsDate,
} from "configs/utils";
import * as SupplementsActions from "redux/supplements/supplements.actions"

import { LAST_CLIENTS_CHART } from "./ChartData";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./newDashboard.styles";
import LeftTop from "./LeftTop";
import RightFixed from "./RightFixed";
import LeftMainContent from "./LeftMainContent";
import moment from "moment";

const dates = {
  startDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    26
  ),
  endDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    25
  ),
}

const NewDashboard = () => {
  const dispatch = useDispatch();

  const [chartData] = useState(LAST_CLIENTS_CHART);
  const [LastFourCustomers, setLastFourCustomers] = useState([]);

  const [InitialDiscussionPercentage, setInitialDiscussionPercentage] =
    useState("");

  const [ActiveClientsPercentage, setActiveClientsPercentage] = useState("");

  const trainerData = useSelector((state) => state.auth.trainerData)

  const onboardingChecks = useSelector(
    (state) => state.dashboard.onboardingChecks?.getTrainerOnboardingChecks
  );
  const invoiceData = useSelector((state) => state.auth.invoiceData)

  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  const initialDiscussionChartDataCurrentMonth = useSelector(
    (state) =>
      state.dashboard.initialDiscussionChartDataCurrentMonth
        ?.getfirstMeetingsMonth
  );

  const initialDiscussionChartDataPreviousMonth = useSelector(
    (state) =>
      state.dashboard.initialDiscussionChartDataPreviousMonth
        ?.getfirstMeetingsMonth
  );

  const activeClientsCurrentMonthChartData = useSelector(
    (state) =>
      state.dashboard.activeClientsCurrentMonthChartData?.getNumberOfClients 
  );

  const activeClientsPreviousMonthChartData = useSelector(
    (state) =>
      state.dashboard.activeClientsPreviousMonthChartData?.getNumberOfClients
  );

  const nextExpertCall = useSelector((state) => state.dashboard.nextExpertCall);
  const trainerNews = useSelector((state) => state.dashboard.trainerNews);

  const isTrainerNewsLoading = useSelector(
    (state) => state.dashboard.isTrainerNewsLoading
  );

  const [ trainerLeads, setTrainerLeads] = useState([]);
  const [ currentMonthTrainerLeads, setCurrentMonthTrainerLeads ] = useState(0);
  const [ lastMonthTrainerLeads, setLastMonthTrainerLeads] = useState(0);

  useEffect(() => {
    dispatch(AuthActions.clearInvoicesByTrainer()) // clear invoice total number
  }, [])

  useEffect(() => {
    if (
      activeClientsCurrentMonthChartData &&
      activeClientsPreviousMonthChartData
    ) {
      setActiveClientsPercentage(
        calculateChartPercentage(
          activeClientsPreviousMonthChartData?.map(
            ({ numberOfActiveClients }) => numberOfActiveClients
          ),
          activeClientsCurrentMonthChartData?.map(
            ({ numberOfActiveClients }) => numberOfActiveClients
          )
        )
      );
    }
  }, [activeClientsCurrentMonthChartData, activeClientsPreviousMonthChartData]);

  useEffect(() => {
    if (
      initialDiscussionChartDataCurrentMonth &&
      initialDiscussionChartDataPreviousMonth
    ) {
      setInitialDiscussionPercentage(
        calculateChartPercentage(
          Object.values(initialDiscussionChartDataPreviousMonth || {}),
          Object.values(initialDiscussionChartDataCurrentMonth || {})
        )
      );
    }
  }, [
    initialDiscussionChartDataCurrentMonth,
    initialDiscussionChartDataPreviousMonth,
  ]);

  useEffect(() => {
    dispatch(
      AuthActions.getTrainerDetailsRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
        trainerID: trainerProfileData?.trainerID,
      })
    );

    dispatch(
      AuthActions.getTrainerProfileRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
      })
    );

    dispatch(
      DashboardActions.getTrainerNewsRequest({
        callback: () => {},
      })
    );

    dispatch(
      DashboardActions.getExpertsCallsRequest({
        callback: () => {
          // const currentDate = new Date().toISOString().split("T")[0];
          // // Find the first item with "Datum" after the current date
          // const foundEvent = expertsCalls?.data?.find(
          //   (event) => event.Datum > currentDate
          // );
          // // Set the next event in the state
          // console.log("foundEvent", foundEvent);
          // setNextEvent(foundEvent);
        },
      })
    );

    dispatch(
      DashboardActions.getInitialDiscussionChartDataPreviousMonthRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month_date: new Date(new Date().setMonth(new Date().getMonth() - 1))
            .toISOString()
            .slice(0, 10),
        },
      })
    );

    dispatch(
      DashboardActions.getInitialDiscussionChartDataRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month_date: new Date().toISOString().slice(0, 10),
        },
      })
    );

    dispatch(
      DashboardActions.getSalesPreviousMonthChartDataRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month_date: new Date(new Date().setMonth(new Date().getMonth() - 1))
            .toISOString()
            .slice(0, 10),
        },
      })
    );

    dispatch(
      DashboardActions.getSalesCurrentMonthChartDataRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month_date: new Date().toISOString().slice(0, 10),
        },
      })
    );

    dispatch(
      DashboardActions.getActiveClientsPreviousMonthChartDataRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month: moment().subtract(1, 'months').format('M'),
          year: moment().subtract(1, 'months').format('YYYY')
        },
      })
    );

    dispatch(
      DashboardActions.getActiveClientsCurrentMonthChartDataRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear()
        },
      })
    );

    dispatch(
      DashboardActions.getTrainerOnBoardingChecksRequest({
        payload: { trainer_id: trainerProfileData?.trainerID },
      })
    );

    dispatch(
      AuthActions.getUsersByTrainerRequest({
        formData: {
          trainerID: trainerProfileData.trainerID,
        },
        page: 1,
        callback: (resOne) => {
          dispatch(
            AuthActions.getUsersByTrainerRequest({
              formData: {
                trainerID: trainerProfileData.trainerID,
              },
              page: resOne?.data?.last_page,
              callback: (resTwo) => {
                if (resTwo?.data?.data.length < 4) {
                  dispatch(
                    AuthActions.getUsersByTrainerRequest({
                      formData: {
                        trainerID: trainerProfileData.trainerID,
                      },
                      page: resTwo?.data?.last_page - 1,
                      callback: (resThree) => {
                        setLastFourCustomers(
                          removeDuplicates([
                            ...LastFourCustomers,
                            ...resTwo?.data?.data.reverse(),
                            ...resThree?.data?.data.reverse(),
                          ])
                        );
                      },
                    })
                  );
                } else {
                  setLastFourCustomers(
                    removeDuplicates([
                      ...LastFourCustomers,
                      ...resTwo?.data?.data.reverse(),
                    ])
                  );
                }
              },
            })
          );
        },
      })
    );

    dispatch(
      AuthActions.getInvoicesByTrainerRequest({
        formData: {
          trainerID: trainerProfileData.trainerID,
          startDate: dates.startDate,
          endDate: dates.endDate,
          showPaidInvoice: true
        },
        page: 1
      })
    )

    getTrainerLeads();
    getCurrentTrainerLeads();
  }, []);

  const onCheckUpdate = (checked, id) => {
    if (checked) {
      dispatch(
        DashboardActions.updateTrainerOnBoardingCheckRequest({
          payload: {
            trainer_id: trainerProfileData?.trainerID,
            check_id: id,
            action: "check",
          },
          callback: () => {
            dispatch(
              DashboardActions.getTrainerOnBoardingChecksRequest({
                payload: { trainer_id: trainerProfileData?.trainerID },
              })
            );
          },
        })
      );
    }
  };

  const handleActiveLastDaysChart = (newDateFilter) => {
    console.log('here : ', newDateFilter)
  };

  const getTrainerLeads = () => {
    dispatch(
      SupplementsActions.getTrainerLeadsRequest({
        payload: {
          connected_trainer: trainerData?.trainerID,
        },
        callback: (res) => {
          let leads = res?.getTrainerLeadsData || [];
          leads = leads.map((lead, i) => ({
            ...lead,
            id: i.toString(),
            lead_id: lead?.lead_id?.toString(),
          }))

          setTrainerLeads(leads)
        },
      })
    )
  }


  const getNumberOfLeads = (month, year) => {
    return new Promise((resolve, reject) => {
      dispatch(
        SupplementsActions.getNumberofLeadsRequest({
          payload: {
            connected_trainer: trainerData?.trainerID,
            month: month,
            year: year,
          },
          callback: (res) => {
            try {
              let leads = res?.getNumberOfLeads || [];
              leads = leads.map((lead, i) => lead?.numberOfLeads);
              const sum = leads.reduce((s, i) => s + i, 0);
              resolve(sum);
            } catch (error) {
              reject(error);
            }
          },
        })
      )
   })
  }

  const getCurrentTrainerLeads = async () => {
    try {
      const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed, so add 1
      const currentYear = new Date().getFullYear();
  
      const currentLeads = await getNumberOfLeads(currentMonth, currentYear);
      const lastLeads = await getNumberOfLeads(currentMonth - 1, currentYear);
      console.log('current month leads : ', currentLeads)
      setCurrentMonthTrainerLeads(currentLeads);
      setLastMonthTrainerLeads(lastLeads);
    } catch (error) {
      console.error("Error fetching trainer leads:", error);
    }
  };


  const leftTopProps = {
    initialDiscussionChartDataCurrentMonth,
    InitialDiscussionPercentage,
    activeClientsCurrentMonthChartData,
    ActiveClientsPercentage,
    invoiceData,
    currentMonthTrainerLeads,
    lastMonthTrainerLeads,
    dates
  }

  const rightFixedProps = {
    trainerProfileData,
    isTrainerNewsLoading,
    trainerNews,
    formatNewsDate
  }

  const leftMainProps = {
    handleActiveLastDaysChart,
    chartData,
    LastFourCustomers,
    nextExpertCall,
    onboardingChecks,
    onCheckUpdate,
    trainerLeads
  }

  return (
    <>
      <S.DashboardContent>
        <S.DashboardHeadline>
          <span>Hi, {trainerProfileData?.prename} ✌️</span>
        </S.DashboardHeadline>
        <S.DashboardWrap>
          <S.DashboardWrapLeft>
            <LeftTop {...leftTopProps} />
            <LeftMainContent {...leftMainProps} />
          </S.DashboardWrapLeft>
          <RightFixed {...rightFixedProps} />
        </S.DashboardWrap>
      </S.DashboardContent>
    </>
  );
};

export default NewDashboard;
